.n-wrapper {
  color: white;
  z-index: 100;
}

.n-container {
  justify-content: space-between;
  padding: 1rem;
  color: var(--text-color);
}

.n-menu {
  gap: 1rem;

}
.n-menu > * {
  font-size: 10px;
}
.n-menu .button {
  font-size: 10px;
}
.n-menu > *:not(:last-child) {
  background: transparent;
  border: none;
  border-radius: 4px;
  transition: all 200ms ease-in;
  padding: 0.5rem;
}

.n-menu a.navbar-link {
  font-size: .9rem;
}
.n-menu > *:not(:last-child):hover {
  cursor: pointer;
  transform: scale(1);
  color: white;
  background: var(--main-color-1);
}

.logoImg {
  width: 60%;
}
.logoImg2 {
  display: none;
}
.menu-icon {
  display: none;
}

@media (max-width: 1150px) {
  .n-menu a.navbar-link {
    font-size: .6rem;
  }
}
@media (min-width: 1001px) {
  .logoImg {
    width: 50%;
    margin: 0 0.5rem;
  }
}
@media (max-width: 1000px) {
  .n-wrapper {
    margin: 0 0.2rem;
  }
  .menu-icon {
    display: block;
  }
  .n-menu {
    z-index: 100;
    color: white;
    position: absolute;
    top: 3rem;
    right: 4rem;
    background: var(--main-color-1);
    flex-direction: column;
    gap: 1rem;
    padding: 3rem;
    border-radius: 10px;
    align-items: center;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    transition: all 300ms ease-in;
  }
  .n-menu > *:not(:last-child) {
    font-weight: 600;
    padding: 0.6rem 1.4rem;
    background: transparent;
    border: none;
    border-radius: 4px;
  }
  .n-menu > *:not(:last-child):hover {
    cursor: pointer;
    transform: scale(1);
    color: var(--main-color-1);
    background: white;
  }
  .navbar-link {
    font-size: 18px;
  }
}
@media (max-width: 450px) {
  .n-container{
    overflow-x: hidden !important;
  }
  .n-container .logoImg {
    display: none !important;
  }
  .n-menu {
    padding: 3rem 0.5rem;
    max-width: 100%;
    overflow-x: hidden;
    transition: all 300ms ease-in;
  }
  .flexCenter.n-menu {
    text-align: center;
}
  .n-container {
    padding: 1rem 0 0 0;
  }
  .n-menu .button {
    font-size: 16px;
  }
}
