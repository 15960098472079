.hero-section {
  background: transparent;
  position: relative;
}
.hero-image {
  width: 1000px;
  height: 550px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.hero-text {
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translate(-50%, -50%);
  width: 650px;
  padding: 4rem 2rem;
  border-radius: 5px;
  color: white;
  text-align: start;
  gap: 1rem;
  backdrop-filter: blur(10px);
  background-color: #12417056;
}
.hero-text h4 {
  font-size: 1.8rem;
}
.hero-text h5 {
  font-size: 1.1rem;
  font-weight: 600;
}
.hero-text p {
  font-size: 0.8rem;
  letter-spacing: 0.8px;
}
.flexStart.buttonSpace {
  margin-top: 1rem;
}
/* //////////////////////////////////// */
/* about in home page */
/* //////////////////////////////////// */
.mt-8 {
  margin-top: 8rem;
}
.aboutHome-container {
  width: 1000px;
  height: 500px;
  letter-spacing: 0.8px;
  justify-content: space-between;
  backdrop-filter: blur(10px);
  background-color: #124170;
  border-radius: 4px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
}
.aboutHome-container > div {
  flex: 1;
}
.rightAbout {
  padding-right: 2rem;
  padding-left: 2rem;
}

.leftAbout {
  gap: 1rem;
  padding-left: 2rem;
}

.image-container-home-about {
  width: 100%;
  border-radius: 4px;
}
/* //////////////////////////////////// */
/* product in home page */
/* //////////////////////////////////// */
.productHome-container {
  width: 1000px;
  gap: 3rem;
}
.home-galleryImg{
gap: 1rem;
}
.card-container {
  width: 200px;
  height: 200px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.card .front-content {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1)
}

.card .front-content img{
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: inherit;}

.card .content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100% );
  color: #e8e8e8;
  padding: 20px;
  line-height: 1.5;
  border-radius: 5px;
  pointer-events: none;
  transform: translateY(96%);
  transition: all 0.6s cubic-bezier(0.23, 1, 0.320, 1);
}

.card .content .heading {
  font-size: 20px;
  font-weight: 700;
}

.card:hover .content {
  transform: translateY(0);
}

.card:hover .front-content {
  transform: translateY(-30%);
}

.card:hover .front-content img {
  opacity: 0;
}


@media (max-width: 1025px) {
  .flexColEnd.rightAbout {
    align-items: start;
  }
  .hero-image,
  .aboutHome-container {
    width: 950px;
    height: 500px;
  }
  .aboutHome-container {
    height: auto;
    padding: 1rem 0;
    gap: 2rem;
  }
  .hero-text h4 {
    font-size: 1.9rem;
  }
  .hero-text h5 {
    font-size: 1rem;
  }
  .hero-text p {
    font-size: 0.9rem;
  }
  .hero-text {
    top: 50%;
    left: 50%;
    width: 750px;
    padding: 4rem;
  }
  .hero-text.flexColStart {
    padding: 3rem 2rem;
  }
  .rightAbout .image-container {
    justify-content: flex-end;
  }
  .rightAbout .image-container img {
    width: 100%;
  }
  .leftAbout {
    margin: 1rem;
    width: 50%;
  }
  .flexCenter.galleryImg {
    gap: 3rem;
  }
  .flexCenter.galleryImg .gallerDiv {
    width: 35%;
  }
  .flexCenter.galleryImg .gallerDiv .button.galleryBtn {
    padding: 0.7rem 2rem;
  }

  @media (max-width: 786px) {
    body {
      overflow-x: hidden;
    }
    .home-galleryImg .home-gallerDiv {
      width: 45%;
      height: 500px;
    }
    .home-galleryImg {
      gap: 1.5rem 2.5rem;
    }
    .hero-image,
    .aboutHome-container {
      width: 600px;
      height: 400px;
    }
    .aboutHome-container {
      height: auto;
      letter-spacing: normal;
    }
    .hero-text h4 {
      font-size: 1.5rem;
    }
    .hero-text h5 {
      font-size: 1rem;
    }
    .hero-text p {
      font-size: 0.9rem;
    }
    .hero-text {
      padding: 3rem 4rem;
      gap: 0.7rem;
      width: 500px;
    }
    .hero-text.flexColStart {
      padding: 2rem;
    }
    .flexStart.buttonSpace > * button {
      padding: 0.6rem 1rem;
    }

    .buttonSpace {
      gap: 0.5rem;
    }
    .aboutHome-container {
      padding: 2rem auto;
    }
    .flexColStart.leftAbout {
      padding: 1rem auto;
      align-items: center;
      justify-content: center;
    }
    .rightAbout {
      display: none;
    }
    .leftAbout {
      justify-content: center;
      padding: 1rem;
      width: 600px;
    }
    .flexCenter.galleryImg {
      flex-direction: column;
    }
    .flexCenter.galleryImg .gallerDiv {
      width: 70%;
      height: 400px;
    }
    @media (max-width: 450px) {
      .aboutHome-container.flexCenter.mt-8,
      .productHome-container.flexCenter.mt-8 {
        margin-top: 3rem;
      }
      .home-galleryImg .home-gallerDiv {
        width: 80%;
        height: 450px;
      }
      .hero-image,
      .aboutHome-container {
        width: 320px;
        height: 600px;
      }
      .aboutHome-container {
        height: auto;
      }
      .hero-text h4 {
        font-size: 1.5rem;
      }
      .hero-text h5 {
        font-size: 1.2rem;
      }
      .hero-text p {
        font-size: 1rem;
      }
      .hero-text {
        padding: 4rem;
        gap: 1rem;
        width: 100%;
        height: 100%;
      }
      .hero-text.flexColStart {
        padding: 0 1.8rem;
      }
      .flexStart.buttonSpace {
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 2rem;
      }
      .flexStart.buttonSpace > * button {
        padding: 0.7rem 5rem;
      }
      .leftAbout {
        width: 300px;
      }

      .flexCenter.galleryImg .gallerDiv {
        width: 90%;
        height: 450px;
      }
      .flexCenter.galleryImg .gallerDiv .button.galleryBtn {
        padding: 0.7rem 5rem;
        margin: 2rem auto;
        display: flex;
        align-items: center;
      }
    }
  }
}
