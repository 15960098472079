body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --main-color-1: #124170;
  --main-color-2: #ffa500;
  --main-color-3: #ff6900;
  /* --main-color-3: #ff0f7b; */
  /* --main-color-3: #ff0f7b; */
  --secondary-text: #ddd;
  --background-color: #faf7f7;
  --text-color: #333333;
  --hover-color: #ffd700;
  --shadow: 0px 23px 21px -8px rgba(136, 160, 255, 0.25),
    0px 23px 21px -8px rgba(136, 160, 255, 0.25);
  --logo-color-2: #b0b1b1;
  --gradient: linear-gradient(to left, var(--main-color-2), #ff6600);
  --gradient-hover: linear-gradient(to bottom, #ff6600, var(--main-color-2));
  --shadow2: inset 0px 1px 91px -10px #124170bf, 0px 23px 21px -8px #88a0ff40;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
  /* background: var(--background-color); */
}
.navbar-link{
  font-family: "Poppins", sans-serif;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  gap: 0.2rem;
}
a {
  color: inherit;
  text-decoration: none;
}
.paddings {
  padding: 1.5rem;
}

.innerWidth {
  width: 100%;
}
.navbar-link-bc {
  background-color: transparent;
}

.flexCenter {
  display: flex;
  row-gap: 2rem;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.flexStart {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.flexEnd {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.flexColCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.flexColStart {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.flexColEnd {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.primaryText {
  color: var(--main-color-1);
  font-weight: bold;
  font-size: 2rem;
}
.primaryTextOrange {
  color: var(--main-color-3);
  font-weight: bold;
  font-size: 2rem;
}
.primaryTextWhite {
  color: white;
  font-weight: bold;
  font-size: 2rem;
}
.primaryTextGray {
  color: var(--secondary-text);
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: var(--secondary-text);
  font-size: 0.9rem;
}
.secondaryTexBlue {
  color: var(--main-color-1);
  font-size: 0.9rem;
}
.secondaryTextWhite {
  color: white;
  font-size: 0.9rem;
}
.orangeText {
  color: var(--main-color-3);
  font-size: 1.5rem;
  font-weight: 600;
}

.button,
.buttonWhite {
  font-weight: 600;
  padding: 0.6rem 1.4rem;
  color: white;
  /* background: var(--gradient); */
  background: linear-gradient(-45deg, #f89b29 0%, #ff0f7b 100% );
  border: none;
  border-radius: 4px;
  transition: all 300ms ease-in;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3); /* Box shadow */

}

.buttonWhite {
  color: var(--main-color-3);
  background: white;
}

.button:hover,
.buttonWhite:hover {
  cursor: pointer;
  transform: scale(1.1);
}
.buttonSpace {
  gap: 0.8rem;
}
.line {
  border: 2px solid var(--main-color-1);
}

.textWrap {
  word-wrap: break-word;
}
.mt-8 {
  margin-top: 8rem;
}
.mt-2 {
  margin-top: 2rem;
}
.border-radius {
  border-radius: 4px;
}
.displayNone{
  display: none;
}
.h5Headers{
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3); 
}
.lineHeight{
  line-height: 1.5rem;
}
/* media queries */
@media (min-width: 1536px) {
  .innerWidth {
    max-width: 1280px;
    margin: auto;
  }
}

@media (min-width: 640px) {
  .paddings {
    padding: 4rem;
  }
  .navbar-link{
    gap: 0.4rem;
  }
}

@media (min-width: 1280px), (min-width: 768px) {
  .paddings {
    padding: 2rem;
  }
}

@media (max-width: 640px) {
  .primaryText {
    font-size: 1.5rem;
  }
  .orangeText {
    font-size: 1.2rem;
  }
}

@media (max-width: 400px) {
  .paddings {
    padding: 1rem;
  }
}
