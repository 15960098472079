.bp-container {
  background: transparent;
}
.bp-image-container {
  position: relative;
}
.bp-image-container img {
  width: 900px;
  height: 550px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin-bottom: 6rem;
  background-position: center center;
  background-size: cover;
}
.bp-text {
  color: white;
  text-align: start;
  font-size: 50px;
  line-height: 58px;
}
.bp-text-container {
  position: absolute;
  top: 60%;
  left: 25%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  backdrop-filter: blur(10px);
  background-color: #12417056;
  border-radius: 5px;
}
.bp-line {
  margin-top: 4rem;
  border: 10px solid var(--main-color-1);
  width: 100%;
}
.bp-container h5 {
  line-height: 2rem;
  padding: 1rem 0;
}
.galleryImg {
  margin-top: 1rem;
  gap: 1rem;
  width: 100%;
}
.galleryImg .gallerDiv {
  width: 30%;
  height: 250px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
}
.text.secondaryTexBlue span{
  font-weight: 900;
  margin-right: 0.5rem;
}
@media (max-width: 1025px) {
  .bp-image-container img {
    width: 950px;
    height: 500px;
  }
}
@media (max-width: 786px) {
  .bp-image-container img {
    width: 650px;
    height: 400px;
  }
  .bp-text-container {
    padding: 2rem;
    left: 30%;
  }
  .bp-text {
    font-size: 2.5rem;
  }
  .galleryImg.flexCenter {
    width: 100%;
  }
  .flexCenter.galleryImg .gallerDiv {
    width: 100%;
  }
}
@media (max-width: 450px) {
  .bp-all-text {
    width: 320px;
  }
  .bp-image-container img {
    width: 320px;
    height: 300px;
  }
  .bp-text-container {
    left: 50%;
    top: 40%;
  }
  .bp-text {
    font-size: 2rem;
    align-self: center;
    text-align: center;
  }
  .primaryText h5{
    font-weight: 900;
  }
  .flexCenter.galleryImg .gallerDiv {
    width: 80%;
    height: 80%;
  }
}
