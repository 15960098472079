.tos-container {
    gap: 2rem;
  }
  .tos-text {
    width: 70%;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
  }
  .tos-text span{
    font-weight: 700;
    display: inline-block;
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
  @media (max-width: 786px) {
    .tos-container.paddings{
    padding: 1rem;
      }
      .tos-text span{
        font-size: 1.2rem;
      
      }
      .tos-text {
        width:80%;
        gap: 2rem;
      }
    }