.p-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-container .product-div {
  gap: 5rem;
}

.product-left {
  flex: 1;
  text-align: left;
  gap: 1rem;
}

.product-right {
  flex: 1;
  text-align: left;
  gap: 1rem;
}

.product-even {
  flex-direction: row-reverse;
}

.product-odd {
  flex-direction: row;
}

.image-container img{
    border-radius: 4px;
    height: 350px;
    width:550px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.5);
    background-position: center center;
    background-size: cover;
}
.p-wrapper .primaryText{
  color: var(--main-color-3);
}
@media (max-width: 1025px) {
  .image-container img{
    height: 280px;
    width:350px;
}
@media (max-width: 450px) {
  .image-container img{
    height: 280px;
    width:280px;
}
.h5Headers{
  font-size: 1.5rem;
}
}
}