.c-container {
  background: transparent;
}
.c-image-container {
  position: relative;
}
.c-image-container img {
  width: 900px;
  height: 550px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  margin-bottom: 6rem;
  background-position: center center;
  background-size: cover;
}
.c-text {
  color: white;
  text-align: start;
  font-size: 50px;
  line-height: 58px;
}
.c-text-container {
  position: absolute;
  top: 60%;
  left: 25%;
  transform: translate(-50%, -50%);
  padding: 3rem;
  backdrop-filter: blur(10px);
  background-color: #12417056;
  border-radius: 5px;
}
.primaryText .text.secondaryTexBlue {
  font-size: 1.1rem;
  font-weight: 500;
}
@media (max-width: 1025px) {
  .c-image-container img {
    width: 950px;
    height: 500px;
  }

  @media (max-width: 786px) {
    .c-image-container img {
      width: 650px;
      height: 400px;
    }
    .c-text-container {
      padding: 2rem;
      left: 30%;
    }
    .c-text {
      font-size: 2.5rem;
    }
    @media (max-width: 450px) {
      .c-image-container img {
        width: 320px;
        height: 300px;
      }
      .c-text-container {
        padding: 2rem;
        left: 50%;
        top: 40%;
      }
      .c-text {
        font-size: 2rem;
        align-self: center;
        text-align: center;
      }
    }
  }
}
