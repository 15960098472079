.slider-wrapper {
  margin-top: 4rem;
}
.slider-div {
  justify-content: space-between;
}
.carousel {
  height: 500px;
}
.carousel .control-dots .dot {
  background: var(--main-color-3);
}
.carousel .carousel-status {
  color: var(--main-color-1);
  text-shadow: none;
  font-size: 12px;
}

.slider-left {
  gap: 1rem;
  width: 50%;
  height: auto;
}

.slider-left img {
  width: 100%;
  height: 350px;
  padding: 0 3rem 0 1rem;
  background-position: center center;
  background-size: cover;
}

.slider-right {
  height: 350px;
  gap: 1rem;
  width: 50%;
  padding-left: 2rem;
  height: auto;
  padding-bottom: 1rem;
}

.slider-right > * {
  align-items: start;
  text-align: start;
}
.slider-buttons {
  gap: 1rem;
  margin-top: 2rem;
  align-items: end;
}

.slider-buttons button {
  border-radius: 50%;
  padding: 0.5rem 1rem;
  font-size: 1rem;
}
.slider-right .primaryText{
  color: var(--main-color-3);
}
@media (max-width: 1025px) {
  .slider-left {
    height: auto;
  }
  .slider-left img {
    width: 80%;
    padding: 0;
  }
  .slider-right {
    height: 400px;
    width: 50%;
    height: auto;
  }
}
@media (max-width: 786px) {
  .slider-wrapper .slider-div.flexCenter {
    flex-direction: column;
  }
  .slider-right,
  .slider-left {
    width: 100%;
  }
  .slider-right {
    padding: 0;
  }
  .slider-left {
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
  }
  .carousel {
    height: auto;
  }
  .slider-left img {
    width: 80%;
    padding: 0;
  }
}
@media (max-width: 450px) {
  .carousel .carousel-status {
    display: none;
  }
  .slider-right,
  .slider-left {
    height: auto;
    width: 75% !important;
    padding-bottom: 1rem;
    /* background-color: #e61919; */
  }
  .slider-buttons {
    display: none;
  }
  .slider-right {
    padding-bottom: 5rem;
  }
  /* .slider-buttons {
    gap: 1rem;
    margin-top: 1rem;
  }

  .slider-buttons button {
    padding: 0.5rem 0.8rem;
  } */
}
