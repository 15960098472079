.pp-container {
  gap: 2rem;

}
.pp-text {
  width: 70%;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}
.pp-text span{
  font-weight: 700;
  display: inline-block;
  margin-bottom: 1rem;
  font-size: 1.5rem;
}
@media (max-width: 786px) {
.pp-container.paddings{
padding: 1rem;
}
.pp-text span{
  font-size: 1.2rem;

}
  .pp-text {
    width:80%;
    gap: 2rem;
  }
}